import { Component } from "react";

class AlertContainer extends Component {
  render() {
    return null;
  }
  componentDidMount() {
    const {
      content,
      options: { context, dismissible },
    } = this.props;

    Array.isArray(content)
      ? content.each(item =>
          Helpers.createToast(
            item,
            item.context ? item.context : null,
            item.dismissible,
          ),
        )
      : Helpers.createToast(content, context ? context : null, dismissible);

    return null;
  }
}

export default AlertContainer;
